import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery.js"
import Modal from "../components/modal.js"

const AlbumPage = () => (
    <Layout>
        <SEO title="Album" description="A collection of images of aircraft and locations mentioned in the novel Flyboys, Round Engines and Spooks written by author Lee Croissant." />
        <>
            <Gallery />
            <Modal />
        </>
    </Layout>
)

export default AlbumPage
