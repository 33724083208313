import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout page="home">
    <SEO title="Home" description="Welcome to the website of Lee Croissant, author of the historical novel Flyboys, Round Engines and Spooks, a semi-autobiographical story of the exploits of a pilot in Southeast Asia during the Vietnam conflict." />
      <div id="home-subhead-two" className="tk-minion-pro-condensed-subhead home-subtitle" style={{textAlign:`center`, fontSize:`32px`}}>A HISTORICAL NOVEL</div>

      <div id="home-subhead-two" className="tk-minion-pro-condensed-subhead home-subtitle" style={{textAlign:`center`, fontSize:`32px`}}>BY LEE CROISSANT</div>

      <div style={{marginLeft:`440px`, marginTop:`20px`, width:`490px`, height:`438px`, textAlign:`justify`}}>
          <p><span style={{fontStyle:`italic`}}>Flyboys, Round engines and spooks</span> tells of the end of the nostalgic propeller era and the end of one pilot's youth and innocence. The story takes place during the Vietnam War era and involves the massive C-124 Globemaster freighter aircraft and World War II C-47 aircraft. The C-47 was modified into a top secret reconnaissance aircraft renamed the EC-47.</p>
          <p style={{marginTop:`8px`}}>This story is a time warp that occurred in the midst of jet fighters, Viet Cong, and rock and roll music—back to those old days, the late forties, pilots wearing leather jackets, and Bogart movies. Scorned by the jet jocks, ignored by the media, these old aircraft operated virtually unknown to the American public.</p>
          <p style={{marginTop:`8px`}}>Join young Eddie Warner as he matures in the nostalgic era of old piston engines, propellers, spark plugs, jungle and Asian splendor. Eddie seeks the mystery woman of his dreams but he discovers not only himself but more intelligence than the military wants him to know. The reader joins Eddie in the cockpit and shares the action and frustrations of flying in America's most controversial war. Witness the top secret operations in the Plain of Jars in northern Laos that were labeled by the press as the CIA's Secret War.</p>
          <p style={{marginTop:`8px`}}>Most of the flying is depicted as it actually happened, the culture of the crewmembers, their life styles, and the revelations are authentic, as are the places depicted. The characters and the story of Eddie's personal experiences and exploits are realistic but fictional.</p>
          <p style={{marginTop:`8px`}}>You will be thinking about what you read and what Eddie revealed . . . a long time after you finish this book.</p>
      </div>
  </Layout>
)

export default IndexPage
