import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NoSpam from "../components/nospam.js"

import bookCover from "../images/BookCover.jpg"

const PurchasePage = () => (
    <Layout page="purchase">
        <SEO title="Purchase the Novel" description="Purchase Flyboys, Round Engines and Spooks written by author Lee Croissant in paperback or ebook form" />
        <a href="http://www.bookcrafters.net" target="_blank" rel="noopener noreferrer"> <img style={{marginLeft:`10px`, marginTop:`10px`}} className="f_left bodyImg" src={bookCover} alt="" /> </a>

        <p style={{textAlign:`center`, marginLeft:`340px`, padding:`10px 40px`}}>
            <span style={{width:`460px`, textAlign:`center`, marginTop:`0px`}}>
                The printed version of <span style={{fontStyle:`italic`}}>Flyboys, Round Engines and Spooks</span> is an 8" X 10" soft cover 365 page book. Within the United States the price is twenty dollars plus three dollars and twenty-five cents shipping and handling. Total $23.25. To order contact Lee Croissant at <NoSpam use="croissant9" dom="comcast" suf="net"/> with the key word Flyboys in the subject line. In the text state the address where you want the book to be sent and what, if anything, you would like to have added to the autograph. If you wish to include chat or have any questions before ordering the book feel free to contact Lee at <NoSpam use="croissant9" dom="comcast" suf="net" subject="Flyboys"/> subject line Flyboys or call at 623-214-4485. Send a check for $23.25 to Lee Croissant at 19607 North Crescent Ridge Drive, Surprise, AZ. 85387. The book will be autographed and mailed to your stated address on the same day the check is received. Autographed books can only be purchased via Lee Croissant.<br /><br />For reviews go to <a href="http://www.amazon.com" target="_blank" rel="noopener noreferrer">www.amazon.com</a> and type Lee Croissant in the search box. The digital book page will come up and the reviews displayed are unsolicited by the author.<br /><br />
                The digital version can be purchased from digital book retailers, such as <a className="bodyLink" href="http://amazon.com/Flyboys-round-engines-spooks-ebook/dp/B009MQB3OU" target="_blank" rel="noopener noreferrer">Amazon</a>, Apple iBookstore, <a className="bodyLink" href="http://www.barnesandnoble.com/w/flyboys-round-engines-and-spooks-lee-croissant/1111603903?ean=2940045074957" target="_blank" rel="noopener noreferrer">Barnes and Noble</a>, <a className="bodyLink" href="http://www.kobobooks.com/ebook/Flyboys-Round-Engines-and-Spooks/book-VehTL_lDB0SPfAtXY79d2A/page1.html?s=9cEYBGXzTEK4qETr2QbQmQ&r=1" target="_blank" rel="noopener noreferrer">Kobo</a> and others. The price is $3.99. <br />
                <br />
                _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _<br />
                <br />
                Contact the Author<br />
                Lee Croissant may be contacted by email, <NoSpam use="croissant9" dom="comcast" suf="net" subject="Flyboys"/>.  Enter the word Flyboys in the subject line. Emails from strangers without Flyboys in the subject line are not opened.
            </span>
        </p>

    </Layout>
)

export default PurchasePage
