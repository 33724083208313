import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import engine from "../images/Barb_and_Lee_737_engine.jpg"
import hawaii from "../images/The_three_of_us_in_Hawaii.jpg"
import cindy from "../images/Lee_and_Cindy_as_teenager.jpg"

const AutobiographyPage = () => (
    <Layout>
        <SEO title="Lee Croissant's Autobiography" description="The autobiography of Lee Croissant, author of the historical fiction novel Flyboys, Round Engines and Spooks" />
        <h1>LEE CROISSANT&rsquo;S AUTOBIOGRAPHY</h1>
        <br />
        <p><img className="f_right bodyImg" src={engine} alt="Lee and Barb standing in front of a 737 engine" />I grew up on an irrigated farm in eastern Colorado and after college spent a summer and fall  working for the Department of Fish and Game in the state of Alaska. In the fall of 1961, in Anchorage, Alaska,  I married my college sweetheart Barbara Husted. Wanting to fly, I joined the air force, passed the battery of tests, and earned a commission as a second  lieutenant. Instead of being assigned to pilot training, as promised, I was  trained to be a navigator and bombardier. After 2 ½ years training I ended up  as a nuclear qualified crewmember on a B-52 alert crew at Dow Air Force Base in  Bangor, Maine. My ambitions to become a pilot were frustrated as I sat on alert  in a concrete bunker facing off with the Russians during the Cold War.</p>
        <br />
        <p><img className="f_left bodyImg" src={hawaii} alt="Lee, Barb and Cindy in Hawaii" />After two years of sitting alert and  flying practice bombing missions, I found a crack in the rigid Strategic Air  Command rules and procedures. Much to the disdain of my squadron and wing  commanders, I managed a reassignment to air force pilot training. It was a  back door maneuver but a fair swap with the air force! They would train me to  fly as a pilot and I knew I would have to serve in Southeast   Asia; where the Vietnamese War was in full bloom. I received my pilot  wings at Williams Air Force Base in Arizona  in 1967. I was sent to Southeast Asia to fly  old World War II era airplanes; piston powered, propeller driven, multi-engine,  transport aircraft. It opened the door for me to experience unique times and  places; an intriguing and nostalgic experience living and flying with the last  of the &ldquo;old prop job&rdquo; crews. I was emerged into Asian splendor, endless jungle,  and adventure unimagined.</p>
        <br />
        <p><img className="f_right bodyImg" src={cindy} alt="Lee with Cindy as teenager" />During  my first two years in Southeast Asia, I flew C-124 cargo aircraft and my wife  lived in Tachikawa, Japan. It was there that she gave  birth to our daughter Cindy. My third year I flew classified reconnaissance  missions in EC-47s, based out of Pleiku and Danang in Vietnam and Nakhon Phanom, Thailand.  I was 28 years old when I flew my first load of freight into Vietnam. Little  did I imagine that three years later I would depart the war zone not only a  seasoned transport pilot but an instructor pilot flying reconnaissance missions  in highly classified EC-47s. It was during my three years in Southeast Asia that  my flying adventures provided me with the wealth of material to write the  historical novel <em style={{fontStyle:`italic`}}>Flyboys, Round Engines  and Spooks.</em> I returned to the United States,  resigned my military commission, and settled in Denver, Colorado.  I flew for various organizations: Saint Anthony&rsquo;s Flight For Life air  ambulance, Emery Air freight, Ports Of Call Travel Club, and United Airlines.  At United I instructed for six years at their pilot training center in Denver,  Colorado, then flew 737s, 727s and DC-10s for fifteen years. Regulations  required I retire at age 60. After several years retirement, I resumed my  career and flew for three years flying precision aerial mapping projects for  various government agencies.</p>
        <br />
        <p>Currently (2012) I am retired from flying  and enjoy my rural home in Colorado.</p>
        <br />
        <br />
        <br />


    </Layout>
)

export default AutobiographyPage
