import React, { Component } from "react"

class RightNav extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        this.props.onRightNavClick();
    }

    render() {
        return (
            <button onClick={this.handleClick} className="ad-forward">&nbsp;</button>
        )
    }
}

export default RightNav
