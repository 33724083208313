import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ExcerptPage = () => (
    <Layout>
        <SEO title="Excerpt from Flyboys, Round Engines and Spooks" description="A brief excerpt from the novel Flyboys, Round Engines and Spooks written by author Lee Croissant." />
        <h1>FLYBOYS, ROUND ENGINES AND SPOOKS</h1>
        <br />
        <h2>EXCERPT</h2>
        <br />
        <p style={{textAlign:`center`}}><strong>CHAPTER ONE: THE CALLING</strong></p>
        <br />
        <p style={{textAlign:`center`}}>December 1969</p>
        <br />
        <p>The two  North Vietnamese MIG 21 fighter aircraft slipped over the ridge of mountains  that separated North Vietnam  from Laos and headed west  into Laos.  Their destination and intended target was a solitary American aircraft. It was  circling aimlessly over the vast expanse of mountains and roadless jungle that stretched  hundreds of miles north from the Laotian Plain of Jars. The sun reflected  brightly off the polished aluminum of their supersonic fighter planes. The  bright red star on their tails displayed the pride and dedication of the two  Russian trained North Vietnamese pilots. Their call sign, Test Flight Two, was  designed to confuse anyone eavesdropping on their radio frequency.</p>
        <br />
        <p>The  operators in the People&rsquo;s Army of Vietnam (North Vietnamese) ground control  intercept radar facility had been waiting for this opportunity. They had been  operational for a week and it was their first opportunity to get a clean kill  on an American aircraft, without risking the loss of their own fighter planes.  Tucked away in a limestone niche, carved just below the peak of a mountain  eight miles inside the North Vietnamese border, they could see with their new radar  well into the northern section of their Laotian neighbors to the west. Their  new ground control intercept (GCI) site made it possible for them to monitor  American aircraft support operations in the highly contested Laotian Plain of  Jars, where the People&rsquo;s Army of Vietnam was battling the fierce Hmong  tribesmen. They could also see far north where Towhead Three Three had been  lazily flying an oblong 50 mile orbit. For this shoot down, the American aircraft  with a call sign of Towhead Three Three would be just right. It would make the  headlines in the American newspapers and would add a new dimension to the war  in Laos.</p>
        <br />
        <p>&ldquo;Test Flight  Two, I have radar lock on. Climb to 22,000 feet and fly heading two seven zero  degrees. I have your traffic 104 miles . . . negative hostiles in the area. Do  not answer.&rdquo; The North Vietnamese radar controller was keeping their radio  usage to a minimum and as ambiguous as possible.</p>
        <br />
        <p>Unfortunately  for Towhead Three Three, this North Vietnamese foray into Laos was taking place during one of  many unproductive and ineffective &ldquo;bombing halts&rdquo; dictated by the American  President. The bombing halt was structured so no American aircraft could fly  anywhere in North Vietnam;  even if in hot pursuit. The North Vietnamese fighters' plan was to shoot down  Towhead Three Three in Laos  and scoot back across their border before the American Air Force could react.  The Americans' own self decreed rules would prevent them from pursuing the MIGs  into North Vietnam.</p>
        <br />
        <p style={{textAlign:`center`}}>* * * * * * * * * * * * * * * * * * * * * * *</p>
        <br />
        <p>In  the cockpit of Towhead Three Three, their radio came to life, &ldquo;Towhead Three  Three, this is Invert on guard emergency frequency. I have two bandits exiting  the fishmouth, position channel 98 bearing zero eight zero at 105 miles DME,  tracking a two seven zero degree course. Towhead Three Three, if you read me,  it looks like they&rsquo;re fast movers coming hell bent to get you. You&rsquo;re the only  aircraft we know about in that area. I&rsquo;d suggest you get the hell out of  there.&rdquo;</p>
        <br />
        <p>Eddie  sat stunned . . . in disbelief. Bandits was the code word for unknown aircraft  of suspected enemy origin, probably enemy MIG fighters. The fishmouth was a  portion of the North Vietnamese border that had a fish mouth shape on the map.  It couldn&rsquo;t be happening to him! With all the antennas his highly modified World  War II C-47 cargo aircraft had, the wind drag was significant. The fastest they  could fly in level flight was 120 knots, which would convert to 138 miles per  hour. The MIGs, as they probably were, would be coming somewhere around 500  knots. To get the hell out of there was not an available option. They were on a  top secret mission, far from any military operations, thus had no American  fighter planes flying cover for them. Probably the nearest American fighter  planes were several hundred miles south, along the Ho Chi Minh Trail.</p>
        <br />
        <p>Eddie  had checked in with Invert, the American ground radar controller after taking  off from Nakhon Phanom Royal Thai Air Force Base. Eddie and his crew were  flying out of the remote air base, located just across the Mekong River  in Thailand, in support of  American covert operations in northern Laos. They had maintained radio  silence thereafter. Eddie knew that while they were crossing over the ancient  Plain of Jars and the high mountains surrounding it, the American &ldquo;Invert&rdquo; radar  had silently monitored their flight path.</p>
        <br />
        <p>Unfortunately,  Eddie&rsquo;s reconnaissance mission required him to fly way north, all the way to  the Chinese border. Up there, they could only be tracked by Invert if they flew  above 18,000 feet. Without cabin pressurization and without supplemental oxygen  on board, flying that high was not an option. Twelve thousand feet was their  sustainable limit. Invert could not see the Towhead EC-47 on their radar but  had locked on to the much higher MIG fighters as they crossed over the dividing  mountain ridge from North    Vietnam. The Invert operator knew Towhead  Three Three was somewhere up there and he was broadcasting the Towhead aircraft  a warning, in the blind. A warning just in case the Towhead could hear him. In  fact, if Eddie&rsquo;s aircraft had been any less than their current 12,000 foot  altitude, he and his seven crew members wouldn&rsquo;t have been able to hear the  radio warning.</p>
        <br />
        <p>&ldquo;Towhead  Three Three, this is Invert broadcasting in the blind. If you hear me, I  estimate eight minutes until the bandits are in your area. We scrambled F-4  fighters out of Udorn. They&rsquo;re coming pedal to the metal. Their estimated time  of arrival to your area is 16 minutes. Do you copy?&rdquo;</p>
        <br />
        <p>&ldquo;I  copy,&rdquo; Eddie broke radio silence, not knowing if Invert could hear him or not.  That meant the bandits, if they really were MIGs, would have six minutes to  shoot him down and a two minute head start back across their border and safety.  That would be easy pickings for the highly maneuverable MIG fighter planes.  They only needed a minute or two to maneuver into position and use their  cannons to rip his unarmed, slow C-47 transport aircraft into shreds.</p>
        <br />
        <p>Eddie  was shocked and upset with their exposed position. Without fighter air cover  and as transport pilots, neither Eddie nor his co-pilot had any air to air  combat tactics training. Even if they had been trained in air combat maneuvers,  it wouldn&rsquo;t have improved their situation. Their slow and low C-47, with its  World War II heritage, and Eddie, were both way out of their league.</p>
        <br />
        <p>&ldquo;What  the hell am I doing here?<em>&rdquo;</em> Eddie  thought to himself. &ldquo;It wasn&rsquo;t supposed to be like this!&rdquo; He looked at the  thick jungle below. &ldquo;Would he, his aircraft, and his crew spend eternity  together down there; scattered among the endless jungle?&rdquo; The pilot&rsquo;s cliché  was &ldquo;bend over and kiss your ass goodbye.&rdquo; There wasn&rsquo;t any room to bend over in  the tiny cockpit so Eddie closed his eyes and tilted his head back . . . </p>

    </Layout>
)

export default ExcerptPage
