import React, { Component } from "react"

import Thumbnail from "./thumbnail.js"

class Thumbnails extends Component {

    getGalleryImages (items) {
        const galleryArray = [];
        items.edges.map((edges, i)=>{
            galleryArray.push(<Thumbnail
                onThumbClick={this.props.onThumbClick}
                item={edges}
                index={i}
            />);
            return "";
        });
        return galleryArray;
    };

    render() {
        return (
            <div className="ad-thumbs">
                <ul className="ad-thumb-list">
                    {this.getGalleryImages(this.props.items)}
                </ul>
            </div>
        )
    }
}

export default Thumbnails

