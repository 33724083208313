import React, { Component } from "react"

import LeftNav from "./leftNav.js"
import Thumbnails from "./thumbnails.js"
import RightNav from "./rightNav.js"

class ThumbnailBar extends Component {
    render() {
        return (
            <div className="ad-nav">
                <LeftNav onLeftNavClick={this.props.onLeftNavClick} />
                <Thumbnails
                    onThumbClick={this.props.onThumbClick}
                    items={this.props.items}
                />
                <RightNav onRightNavClick={this.props.onRightNavClick} />
            </div>
        )
    }
}

export default ThumbnailBar
