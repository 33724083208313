import React from "react"

function handleClose(e){
    const modal = document.getElementById("modal");
    modal.classList.add('fade-out-slow');
    setTimeout(() => {
        modal.style.display= "none";
        modal.classList.remove('fade-out-slow');
        modal.classList.remove('fade-in-slow');
    }, 1000);

    e.preventDefault();
}

function Modal(){
    return (
        <div id="modal" className="modal">
            <div className="modal-wrap modal-desktop modal-type-image modal-opened">
                <div id="modal-skin" className="modal-skin">
                    <div className="modal-outer">
                        <div className="modal-inner">
                            <img id="modal-image" className="modal-image" src="" alt="" />
                        </div>
                    </div>
                    <button onClick={ handleClose } title="Close" className="modal-item modal-close"></button>
                </div>
             </div>
             <div id="modal-overlay" className="overlay-fixed"></div>
        </div>
    )
}

export default Modal