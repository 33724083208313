import React, { Component } from "react"

class LeftNav extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        this.props.onLeftNavClick();
    }

    render() {
        return (
            <button onClick={this.handleClick} className="ad-back">&nbsp;</button>
        )
    }
}

export default LeftNav
