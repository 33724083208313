import React, { Component } from "react"

import MainImage from "../components/gallery/mainImage.js"
import ThumbnailBar from "../components/gallery/thumbnailBar.js"

import { StaticQuery, graphql } from "gatsby"


class Gallery extends Component {
    constructor (props){
        super(props);
        this.state = {
            currentPage: 1,
            pages : 13,
            windowWidth : 600
        }
        this.handleLeftNavClick = this.handleLeftNavClick.bind(this)
        this.handleRightNavClick = this.handleRightNavClick.bind(this)
        this.handleThumbClick = this.handleThumbClick.bind(this)
        this.handleMainClick = this.handleMainClick.bind(this)
    }

    handleLeftNavClick() {
        const thumbList = document.getElementsByClassName('ad-thumb-list')[0];
        if(this.state.currentPage > 1){
            let newPage = this.state.currentPage - 1;
            this.setState({currentPage:newPage});
            let offset = parseInt((newPage-1) * this.state.windowWidth);
            thumbList.style.left = "-"+offset+"px";
        }
    }

    handleRightNavClick(){
        const thumbList = document.getElementsByClassName('ad-thumb-list')[0];
        if(this.state.currentPage < (this.state.pages-1)){
            let offset = parseInt(this.state.currentPage * this.state.windowWidth);
            thumbList.style.left = "-"+offset+"px";
            let newPage = this.state.currentPage + 1;
            this.setState({currentPage:newPage});
        }
    }

    handleThumbClick(e){
        const mainImage = document.getElementById("ad-main-image");
        const subImage = document.getElementById("ad-sub-image");
        const modalImage = document.getElementById("modal-image");
        const captionWrapper = document.getElementById("ad-caption-wrapper");
        const caption = document.getElementById("ad-caption");
        const subcaption = document.getElementById("ad-subcaption");
        const subcaptionWrapper = document.getElementById("ad-subcaption-wrapper");
        const link = document.getElementById("ad-main-link");
        const newSource = e.target.src;
        const newSourceRel = e.target.getAttribute('rel');
        const newAlt = e.target.alt;
        const transitionStyle = "sequential" //sequential or crossfade

        if(transitionStyle === 'crossfade') {
            //setup subImage w/ caption
            subImage.src = newSource;
            modalImage.src = newSource;
            link.href = newSource;
            subcaption.innerText = newAlt;
            captionWrapper.style.opacity = 0;

            setTimeout(() => {
                const imgWidth = subImage.offsetWidth;
                if (imgWidth >= 600) {
                    subImage.style.left = 0;
                    subcaptionWrapper.style.left = 0;
                    subcaptionWrapper.style.width = "600px";
                } else {
                    const leftOffset = parseInt((600 - imgWidth) / 2);
                    subImage.style.left = leftOffset + "px";
                    subcaptionWrapper.style.left = leftOffset + "px";
                    subcaptionWrapper.style.width = (imgWidth - 14) + "px";
                }
                //allow delay for subImage to load
                //fadeout mainImage
                this.fadeOut(mainImage);
            }, 100);

            //replicate MainImage
            setTimeout(() => {
                const imgWidth = subImage.offsetWidth;
                if (imgWidth >= 600) {
                    mainImage.style.left = 0;
                    captionWrapper.style.left = 0;
                    captionWrapper.style.width = "600px";
                } else {
                    const leftOffset = parseInt((600 - imgWidth) / 2);
                    mainImage.style.left = leftOffset + "px";
                    captionWrapper.style.left = leftOffset + "px";
                    captionWrapper.style.width = (imgWidth - 14) + "px";
                }

                mainImage.src = newSource;
                mainImage.setAttribute('rel', newSourceRel);
                caption.innerText = newAlt;

                //allow delay for main image to change

                setTimeout(() => {
                    //show main image and caption
                    this.fadeReset(mainImage);
                    this.fadeReset(caption);
                }, 100);
            }, 400);
        } else if (transitionStyle === 'sequential'){
            //hide subimage
            this.hide(subImage);
            this.hide(subcaptionWrapper);

            //fadeout main image and caption and pre-load image to subimage while main is fading out
            subImage.src = newSource;
            modalImage.src = newSourceRel;
            subcaption.innerText = newAlt;
            this.fadeOut(mainImage);
            this.fadeOut(captionWrapper);

            //after main has faded, fadein subimage and subcaption
            setTimeout(() => {
                //first shift position and width as required
                const imgWidth = subImage.offsetWidth;
                if (imgWidth >= 600) {
                    mainImage.style.left = 0;
                    subImage.style.left = 0;
                    captionWrapper.style.left = 0;
                    captionWrapper.style.width = "600px";
                    subcaptionWrapper.style.left = 0;
                    subcaptionWrapper.style.width = "600px";
                } else {
                    const leftOffset = parseInt((600 - imgWidth) / 2);
                    mainImage.style.left = leftOffset + "px";
                    subImage.style.left = leftOffset + "px";
                    captionWrapper.style.left = leftOffset + "px";
                    captionWrapper.style.width = (imgWidth - 14) + "px";
                    subcaptionWrapper.style.left = leftOffset + "px";
                    subcaptionWrapper.style.width = (imgWidth - 14) + "px";
                }

                this.fadeIn(subImage, '-slow');
                this.fadeIn(subcaptionWrapper, '-slow');

                //replicate changes to main image
                mainImage.src = newSource;
                mainImage.setAttribute('rel', newSourceRel);
                caption.innerText = newAlt;

                //show main image
                setTimeout(() => {
                    this.fadeReset(mainImage);
                    this.fadeReset(captionWrapper);
                    this.fadeReset(subImage);
                    this.fadeReset(subcaptionWrapper);
                }, 900);
            }, 300);
        }

        document.querySelector(".ad-active").classList.remove("ad-active");
        e.currentTarget.classList.add("ad-active");
    }

    handleMainClick(e){
        const newSource = e.target.getAttribute('rel');
        const modalImage = document.getElementById("modal-image");
        modalImage.src = newSource;
        const modal = document.getElementById("modal");
        modal.style.display= "block";

       setTimeout(()=>{
            const imgWidth = modalImage.offsetWidth;
            const skin = document.getElementById('modal-skin');
            skin.style.left = parseInt(((840 - imgWidth - 30)/2)) + 'px';
            this.fadeIn(modal,"-slow");
        },100);


        e.preventDefault();
    }

    hide(element){
        element.classList.add('visually-hide');
        element.classList.remove('visually-show');
    }

    show(element){
        element.classList.add('visually-show');
        element.classList.remove('visually-hide');
    }

    fadeIn(element, speed=""){
        element.classList.remove('fade-out'+speed);
        element.classList.add('fade-in'+speed);
    }

    fadeOut(element, speed=""){
        element.classList.remove('fade-in'+speed);
        element.classList.add('fade-out'+speed);
    }

    fadeReset(element){
        element.classList.remove('fade-out');
        element.classList.remove('fade-in');
        element.classList.remove('fade-out-slow');
        element.classList.remove('fade-in-slow');
    }

    render(){
        return (
            <div className="ad-gallery">
                <div className="ad-image-wrapper">
                    <MainImage
                        onMainClick={this.handleMainClick}
                        items={this.props.items}
                    />
                </div>
                <div className="ad-controls"></div>
                <ThumbnailBar
                    onLeftNavClick={this.handleLeftNavClick}
                    onRightNavClick={this.handleRightNavClick}
                    onThumbClick={this.handleThumbClick}
                    items={this.props.items}
                />
            </div>
        )
    }
}


export default () => (
    <StaticQuery
        query={graphql`query GalleryDataQuery {
                          allGalleryJson {
                            edges {
                              node {
                                image
                                title
                                caption
                              }
                            }
                          }
                        }
                     `}
        render={data=>( <Gallery items={data.allGalleryJson} /> )}
    />
)

//Built to serve fixed images. See https://github.com/gatsbyjs/gatsby/issues/12916 for info on building this using fluid images
