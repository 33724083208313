/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
      <div id="wrapper" className={page}>
          <div id="innerWrapper">
              <Header siteTitle={data.site.siteMetadata.title} />
              <main id="page">{children}</main>
              <footer>
                  <div style={{marginTop:`20px`}}>
                      <iframe title="Facebook Links" src="https://www.facebook.com/plugins/like.php?href=www.leecroissant.com&layout=standard&show_faces=false" scrolling="no" frameBorder="0" style={{width:`225px`, height:`35px`}}></iframe>
                  </div>
                  <div id="copyright">&copy; 2012 - {new Date().getFullYear()} Lee Croissant. All Rights Reserved.</div>
              </footer>
          </div>
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
