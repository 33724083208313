// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-album-js": () => import("/opt/build/repo/src/pages/album.js" /* webpackChunkName: "component---src-pages-album-js" */),
  "component---src-pages-autobiography-js": () => import("/opt/build/repo/src/pages/autobiography.js" /* webpackChunkName: "component---src-pages-autobiography-js" */),
  "component---src-pages-excerpt-from-novel-js": () => import("/opt/build/repo/src/pages/excerpt-from-novel.js" /* webpackChunkName: "component---src-pages-excerpt-from-novel-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-purchase-js": () => import("/opt/build/repo/src/pages/purchase.js" /* webpackChunkName: "component---src-pages-purchase-js" */)
}

