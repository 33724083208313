import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
    <div id="header">
        <nav id="nav">
            <ul>
                <li className="first"><a href="/">HOME</a></li>
                <li><Link to="/autobiography">AUTOBIOGRAPHY</Link></li>
                <li><Link to="/excerpt-from-novel">EXCERPT FROM THE NOVEL</Link></li>
                <li><Link to="/purchase">PURCHASE THE NOVEL</Link></li>
                <li className="last"><Link to="/album">LEE'S PHOTO ALBUM</Link></li>
            </ul>
        </nav>
        <div id="headerTitle" className="tk-minion-pro-condensed-subhead head-title" style={{fontSize:`48px`, width:`960px`, textAlign:`center`}}>
            <Link to="/">FLYBOYS, ROUND ENGINES AND SPOOKS</Link>
        </div>
    </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
