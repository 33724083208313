import React, { Component } from "react"

import Caption from "./caption.js"

class MainImage extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        this.props.onMainClick(e);
        e.preventDefault();
    }

    render() {
        const items = this.props.items;

        return (
            <div className="ad-image" style={{width: `600px`, height: `360px`, top: `20px`, left: `0px`}}>
                <a id="ad-main-link" onClick={this.handleClick} href={`/media/large/`+items.edges[0].node.image} target="_blank" rel="noopener noreferrer">
                    <img id="ad-sub-image" className="ad-sub-image" src={`/media/`+items.edges[0].node.image} alt={items.edges[0].node.title}/>
                    <img id="ad-main-image" className="ad-main-image" rel={`/media/large/`+items.edges[0].node.image} src={`/media/`+items.edges[0].node.image} alt={items.edges[0].node.title}/>
                </a>
                <Caption caption={items.edges[0].node.title}/>
            </div>
        )
    }
}

export default MainImage
