import React from "react"

const NoSpam = ({use, dom, suf, subject}) => {
    let response = '';
    if(subject){
        response = React.createElement('a', {className: 'bodyLink', href:'mailto:'+use+'@'+dom+'.'+suf+'?Subject='+subject}, use+'@'+dom+'.'+suf)
    } else {
        response = React.createElement('a', {className: 'bodyLink', href:'mailto:'+use+'@'+dom+'.'+suf}, use+'@'+dom+'.'+suf)
    }
    return ( response )
}

export default NoSpam
