import React, { Component } from "react"

class Thumbnail extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        this.props.onThumbClick(e);
        e.preventDefault();
    }

    getThumbClass(index){
        if(index === 0){
            return "ad-active"
        }
        return ""
    }

    render() {
        return (
            <li key={this.props.index}>
                <a onClick={this.handleClick}  className={this.getThumbClass(this.props.index)} href={`/media/`+this.props.item.node.image}> <img
                    style={{height:`50px`}} src={`/media/`+this.props.item.node.image} rel={`/media/large/`+this.props.item.node.image} alt={this.props.item.node.title}/></a>
            </li>
        )
    }
}

export default Thumbnail
