import React, { Component } from "react"

class Caption extends Component {
    render(){
        return (
            <>
                <p id="ad-caption-wrapper" className="ad-image-description">
                    <span id="ad-caption">{this.props.caption}</span>
                </p>
                <p id="ad-subcaption-wrapper" className="ad-image-description">
                <span id="ad-subcaption">{this.props.caption}</span>
                </p>
            </>
        )
    }
}

export default Caption
